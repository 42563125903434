import * as React from "react"
import Layout from "../../components/layout"
import Subscribe from "../../components/subscribe"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
// import { ChevronRightIcon } from '@heroicons/react/20/solid'

// exective team
const leaders = [
  {
    name: 'Judi Nudelman',
    role: 'Founder and Board President ',
    imageUrl: (
      <StaticImage
        src='../../images/LeadershipJudi.jpg'
        alt='Judi Nudelman'
      />
    ),
    bio: 'Judi Nudelman entered the computer field in 1962 when few women were professionals in the corporate and STEM worlds.  Throughout her thirty year  career at IBM, Judi broke new ground.  In 1971, when her first son was born, she was the first working mother in a Houston location of 1,000 people.  Judi encountered obstacles as a working mother and as a woman who spoke “truth to power”.  Using her organizational skills and IBM’s support structures, she confronted obstacles and moved up the career ladder to  positions in national and regional headquarters and in management positions.  Since retiring in 1991, Judi has continued to use her experience and skills to establish new organizations and assist people. In 2018, Judi’s husband Harvey, a respected Neuroscientist, passed away after a battle with  the rare neurodegenerative disease, Progressive Supranuclear Palsy (PSP).  Judi founded Women in Neuroscience  to honor his memory and inspire more women to join the battle against brain disease.  She has used her organizational skills, experience, and personal network to fund and establish a strong internship model to “build a diverse community of female leaders in neuroscience and neurology."',
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/judi-nudelman-51b2a2136/',
    emailUrl: 'mailto:judi@neurowomen.org',
  },
  {
    name: 'Rebecca Farrell Ewing, PhD',
    role: 'Executive Director',
    imageUrl: (
      <StaticImage
        src='../../images/LeadershipRebecca.jpg'
        alt='Rebecca Farrell Ewing'
      />
    ),
    bio: 'With over twenty years of experience, Rebecca Farrell Ewing, Ph.D., LPCC-S (KY), has dedicated her career to empowering individuals to thrive in their overall health and wellness while honoring their autonomy, purpose, and dignity. Her work is rooted in principles of intersectionality, inclusivity, equity, social justice, and diversity. Dr. Farrell Ewing has held leadership roles in both nonprofit and for-profit organizations, alongside her teaching experience in higher education. Her dedication to mobilizing communities and resources through collaborations, partnerships, and policy reforms is highlighted by her active involvement in various multidisciplinary teams, boards of directors, task forces, and committees.',
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/rebecca-farrell-ewing-ph-d-b51803276/',
    emailUrl: 'mailto:rebecca@neurowomen.org',
  },
  {
    name: 'Emily Pickens',
    role: 'Director of Programs',
    imageUrl: (
      <StaticImage
        src='../../images/LeadershipEmily.jpg'
        alt='Emily Morris'
      />
    ),
    bio: ['Emily Pickens, MBA, MT-BC  brings to the table a strong background in Neurologic Music Therapy and Business, as well as a lifelong passion for service, volunteerism and advocacy. This unique blend of skills, experience, and ethos make her a natural fit to advance the organization’s mission of women’s STEM empowerment.', 
    <br/>,
    <br/>,
    'When she’s not working, you can find Emily singing, getting lost in the Green Belt, roller-skating, binging old seasons of Survivor, cooking delicious, healthy food, or reading the latest Harlan Coban novel.'],
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/emily-morris-pickens-5589605a/',
    emailUrl: 'mailto:emily@neurowomen.org',
  },
  // More leaders...
]

// board of directors
const people = [
  {
    name: 'Socar Chatmon-Thomas',
    role: null,
    bio: 'Broker/Owner, Elegant Estates by Audition; past chair, Austin Board of Realtors; Director, Texas and National Association of Realtors; parent of WiN intern',
    imageUrl: (
      <StaticImage
        src='../../images/BoardSocar.jpg'
        alt='Socar Chatmon-Thomas'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: 'https://twitter.com/socar_thomas',
    linkedinUrl: 'https://www.linkedin.com/in/socarthomas/',
  },
  {
    name: 'Gail Kaplan',
    role: null,
    bio: `Entrepreneur; retired legal recruiter; Harvey and Judi's niece`,
    imageUrl: (
      <StaticImage
        src='../../images/BoardGail.jpg'
        alt='Gail Kaplan'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/gail-kaplan-366438/',
  },
  {
    name: 'Judi Nudelman',
    role: `Founder and Board President`,
    bio: `Retired IBM Manager; Leader, CurePSP Central Texas Support Group`,
    imageUrl: (
      <StaticImage
        src='../../images/LeadershipJudi.jpg'
        alt='Judi Nudelman'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/judi-nudelman-51b2a2136/',
  },
  {
    name: 'Rebecca Farrell Ewing, PhD',
    role: `Executive Director of WiN`,
    bio: null,
    imageUrl: (
      <StaticImage
        src='../../images/LeadershipRebecca.jpg'
        alt='Judi Nudelman'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/rebecca-farrell-ewing-ph-d-b51803276/',
  },
  {
    name: 'Jim Walb',
    role: 'Treasurer and Finance Committee Chair',
    bio: `Retired Wall Street senior executive; past chair, UNICEF NY Metropolitan Chapter; co-founder, Leadership Equality Academy; board advisor, Classvisa`,
    imageUrl: (
      <StaticImage
        src='../../images/BoardJim.jpg'
        alt='Jim Walb'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: 'https://www.linkedin.com/in/jim-walb-aa4604/',
  },
  // {
  //   name: 'Janet Walkow, PhD',
  //   bio: `Executive Director and Chief Technology Officer, Drug Dynamics Institute; Clinical Professor of Molecular Pharmaceutics and Drug Delivery, UT Austin`,
  //   role: null,
  //   imageUrl: (
  //     <StaticImage
  //       src='../../images/BoardJanet.jpg'
  //       alt='Janet Walkow'
  //       className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
  //     />
  //   ),
  //   twitterUrl: 'https://twitter.com/LWPjanet',
  //   linkedinUrl: 'https://www.linkedin.com/in/janetwalkow/',
  // },
  {
    name: 'Micky Marinelli, PhD',
    bio: `Associate Professor of Neuroscience, UT Austin; Associate Professor Department of Neurology, Dell Medical School, WiN Founding Mentor and Lab Host`,
    role: null,
    imageUrl: (
      <StaticImage
        src='../../images/BoardMickey.jpg'
        alt='Micky Marinelli'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: 'https://twitter.com/mickymarinelli',
    linkedinUrl: 'https://www.linkedin.com/in/michela-micky-marinelli-4bab251/',
  },
  {
    name: 'Kaelin Rubenzer',
    bio: `WiN Alumni; BS In Neuroscience from UT Austin; Neuroscience Researcher at UT; Program coordinator and instructor for LLWF`,
    role: null,
    imageUrl: (
      <StaticImage
        src='../../images/BoardKaelin.jpg'
        alt='Kaelin Rubenzer'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: null,
  },
  {
    name: 'Mary Abreu, PhD',
    bio: `Neuropharmacologist; Retired from leadership roles across the spectrum of drug development`,
    role: 'Secretary',
    imageUrl: (
      <StaticImage
        src='../../images/BoardMaryAbreu.jpg'
        alt='Mary Abreu, PhD'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: null,
  },
  {
    name: 'Z. Leah Harris, MD',
    bio: `Chair, Department of Pediatrics, Professor of Pediatrics, Dell Medical School; Physician-in-Chief, Dell Children's Medical Center; Director, Dell Pediatric Research Institute`,
    role: null,
    imageUrl: (
      <StaticImage
        src='../../images/BoardLeahHarris.jpg'
        alt='Z. Leah Harris, MD'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: null,
  },
  {
    name: 'Derek Eckert, CFP',
    bio: `Investment Advisor and Managing Director, Syntropy Wealth Management; Past Board Member, Texas Sierra Club`,
    role: null,
    imageUrl: (
      <StaticImage
        src='../../images/BoardDerekEckert.jpg'
        alt='Derek Eckert, CFP'
        className="mx-auto h-40 w-40 object-cover border-black border-solid border-2 rounded-full xl:h-56 xl:w-56"
      />
    ),
    twitterUrl: null,
    linkedinUrl: null,
  },
  // More people...
]

const leadershipPage = () => (
    <Layout siteTitle={"Harvey and Judi's Story"} >

    {/* top banner */}
    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/LeadershipBanner.jpg"
          // alt=""
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Leadership</h1>
        <p className="mt-6 font-light max-w-3xl text-xl text-indigo-100">
        Our team is passionate about creating opportunities for young women from historically excluded groups and encouraging them to be trailblazers in the fields of neuroscience and neurology
        </p>
      </div>
    </div>

    {/* executive team profiles */}
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Executive Team</h2>
            <p className="text-xl text-gray-500">
            Our executive leadership sets the overall strategic direction for WiN, as well as oversees the day-to-day operations—including program implementation and development, fundraising initiatives, and spearheading partnerships with other organizations, institutions, and individuals to support these efforts.
            </p>
            <a href="/join-us/open-positions" className="inline-flex space-x-6">
              
              {/* <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-indigo-600 hover:text-gray-600">
                <span>We're hiring! Apply to be WiN's next Executive Director</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span> */}

            </a>
          </div>
          
          <div className="lg:col-span-2">
            <ul
              className="space-y-12 sm:-mt-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 lg:gap-x-8 lg:space-y-0"
            >
              {leaders.map((leader) => (
                  <li key={leader.name} className="sm:py-8">
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="sm:!aspect-w-3 sm:!aspect-h-4 !h-350px flex justify-center items-start bg-top">
                    {leader.imageUrl}
                  </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>{leader.name}</h3>
                          <p className="text-indigo-600">{leader.role}</p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">{leader.bio}</p>
                        </div>
                        <ul className="flex space-x-5">
                          {leader.twitterUrl && (
                            <li>
                              <a
                                href={leader.twitterUrl}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">Twitter</span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                              </a>
                            </li>
                          )}
                           {leader.linkedinUrl && (
                            <li>
                              <a
                                href={leader.linkedinUrl}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">LinkedIn</span>
                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              </a>
                            </li>
                          )}
                             {leader.emailUrl && (
                            <li>
                              <a
                                href={leader.emailUrl}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">LinkedIn</span>
                                <svg className="h-5 w-5" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2 5L21 22L40 5V36C40 36.553 39.553 37 39 37H3C2.447 37 2 36.553 2 36V5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <rect x="2" y="5" width="38" height="32" rx="2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></rect>
                                </svg>

                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>

    {/* board profiles */}
    <div className="bg-white pb-8">
      <div className="mx-auto max-w-7xl px-6 text-center">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Board of Directors</h2>
            <p className="text-xl text-gray-500">
            Our board is responsible for providing governance and oversight, including setting policies, approving budgets, and ensuring the organization's compliance with legal and ethical standards. Additionally, they serve as WiN ambassadors, helping to raise awareness and support for our mission and goals.
            </p>
          </div>
          <ul
            className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-3"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-6">
                    {person.imageUrl}
                  <div className="space-y-2">
                    <div className="space-y-1 text-lg font-medium leading-6">
                      <h3>{person.name}</h3>
                      {person.role && (
                      <p className="text-indigo-600">{person.role}</p>
                      )}
                      {person.bio && (
                      <p className="text-gray-500">{person.bio}</p>
                      )}
                    </div>
                    <ul className="flex justify-center space-x-5">
                    {person.twitterUrl && (
                            <li>
                              <a
                                href={person.twitterUrl}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">Twitter</span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                              </a>
                            </li>
                          )}
                           {person.linkedinUrl && (
                            <li>
                              <a
                                href={person.linkedinUrl}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">LinkedIn</span>
                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              </a>
                            </li>
                          )}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

    {/* email subscribe */}
    <Subscribe />

    </Layout>
)

export const Head = () => <Seo title="WiN Leadership" />

export default leadershipPage